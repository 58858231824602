export default {
  id: '',
  collect_channel_id: '',
  name_1: '',
  name_2: '',
  bank_account: '',
  bank_last_digits: '',
  status: false,
  enabled_auto_collect: false,
  abnormal_status: '',
  priority: 0,
  min_balance: 0,
  max_balance: 0
};
