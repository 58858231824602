<template>
  <v-dialog
    :value="value"
    scrollable
    persistent
    :overlay="false"
    max-width="800"
    transition="slide-y-transition"
  >
    <v-card>
      <v-card-title class="pos-r">
        {{ localData.mode === 'new' ? '新增' : '編輯' }}子帳戶

        <v-spacer></v-spacer>
        <v-btn
          rounded
          color="Secondary100"
          fab
          x-small
          class="b-rad-12"
          @click="close"
        >
          <v-icon>mdi-window-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text>
        <SizeBox height="48" />
        <v-form ref="form">
          <v-row>
            <v-col cols="12">
              <v-text-field
                rounded
                label="銀行名稱"
                v-model="form.bank_name"
                :rules="[$rules.required]"
                outlined
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field
                rounded
                label="銀行分行"
                v-model="form.bank_branch_name"
                :rules="[$rules.required]"
                outlined
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field
                rounded
                label="銀行帳號"
                v-model="form.bank_account"
                :rules="[$rules.required]"
                outlined
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field
                rounded
                label="帳號末 4 碼"
                :value="bankLastDigits"
                readonly
                outlined
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field
                rounded
                label="銀行戶名"
                v-model="form.bank_holder"
                :rules="[$rules.required]"
                outlined
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field
                rounded
                label="名稱1"
                :value="name1"
                readonly
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                rounded
                label="名稱2"
                :value="name2"
                readonly
                outlined
              ></v-text-field>
            </v-col>

            <v-col cols="12" v-if="localData.mode !== 'new'">
              <v-text-field
                rounded
                label="使用者帳號"
                v-model="form.user_account"
                outlined
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <c-autocomplete
                rounded
                label="指定通道"
                v-model="form.collect_channel_id"
                :rules="[$rules.required]"
                :api="getCollectChannels"
              ></c-autocomplete>
            </v-col>

            <v-col cols="12">
              <v-text-field
                rounded
                label="輪詢權重"
                hint="數字越大 優先越高"
                v-model.number="form.priority"
                @change="
                  (form.priority > 9 && (form.priority = 9)) ||
                    (form.priority < 1 && (form.priority = 1))
                "
                :rules="[(v) => typeof v === 'number' || required(v)]"
                min="1"
                max="9"
                type="number"
                outlined
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field
                rounded
                label="初始餘額"
                v-model.number="form.balance"
                type="number"
                persistent-hint
                outlined
              ></v-text-field>
            </v-col>

            <v-col cols="6">
              <v-text-field
                rounded
                label="最低餘額限制"
                v-model.number="form.min_balance"
                type="number"
                :rules="[$rules.required]"
                outlined
              ></v-text-field>
            </v-col>

            <v-col cols="6">
              <v-text-field
                rounded
                label="最高餘額限制"
                v-model.number="form.max_balance"
                type="number"
                :rules="[$rules.required]"
                outlined
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-select
                rounded
                label="異常狀態"
                v-model="form.abnormal_status"
                :items="[
                  { value: '', text: '正常' },
                  { value: 'zero', text: '餘額為 0', disabled: !!form.balance },
                  { value: 'frozen', text: '銀行停用' },
                  { value: 'reach_max', text: '滿額' },
                  { value: 'risk_control', text: '銀行風控' },
                  { value: 'judiciary', text: '銀行司法' },
                  { value: 'cancelled', text: '銀行註銷' },
                  { value: 'reach_min', text: '已達最低餘額限制' }
                ]"
                mandatory
                outlined
              ></v-select>
            </v-col>

            <v-col cols="auto">
              <v-switch
                label="帳戶狀態"
                v-model="form.status"
                type="number"
                outlined
                dense
                hide-details
                inset
              ></v-switch>
            </v-col>
            <v-col cols="auto">
              <v-switch
                label="自動上分"
                v-model="form.enabled_auto_collect"
                type="number"
                outlined
                dense
                hide-details
                inset
              ></v-switch>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-btn rounded color="Secondary100" depressed large @click="close">
          取消
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn rounded color="primary" dark depressed large @click="submit">
          {{ localData.mode === 'new' ? '新增子帳戶' : '保存' }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import schema from './schema';
import { getCollectChannels } from '@/api/collectChannels';
import { addCard, editCard } from '@/api/cards';

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    passData: {
      type: Object,
      default: () => ({
        /**
         * mode: String, // new, edit
         */
      })
    },
    channleTypes: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      getCollectChannels,

      localData: {
        mode: 'new'
      },
      form: {
        collect_channel_id: '',
        bank_account: '',
        status: true,
        enabled_auto_collect: true,
        abnormal_status: 'zero',
        priority: 1,
        min_balance: 0,
        max_balance: 0,
        bank_holder: '',
        bank_name: '',
        bank_branch_name: '',
        balance: 0,
        user_account: ''
      }
    };
  },

  computed: {
    readonly() {
      return this.localData.mode === 'view';
    },
    bankLastDigits() {
      return this.form.bank_account.slice(-4);
    },
    name1() {
      return `${this.form.bank_holder}-${this.form.bank_name.replace(
        /銀行|银行/g,
        ''
      )}-${this.bankLastDigits}`;
    },
    name2() {
      return `${this.form.bank_holder}-${this.form.bank_name}`;
    }
  },

  watch: {
    value(v) {
      v ? this.init() : this.reset();
    },
    'form.balance': {
      handler(v, a) {
        if (!a && !!v) {
          this.form.abnormal_status = '';
        }
        if (!!a && !v) {
          this.form.abnormal_status = 'zero';
        }
      }
    }
  },

  methods: {
    init() {
      this.localData.mode = this.passData.mode;
      if (this.passData.mode !== 'new') {
        this.$set(this, 'localData', { ...schema, ...this.passData });
        Object.keys(this.form).forEach((key) => {
          typeof this.passData[key] !== 'undefined' &&
            (this.form[key] = this.passData[key]);
        });
      }
      this.$refs.form && this.$refs.form.resetValidation();

      this.$nextTick(() => {
        this.form.abnormal_status = this.passData.abnormal_status;
        this.form.user_account = this.passData?.user?.account || '';
      });
    },
    reset() {
      const originData = this.$options.data();
      Object.keys(originData).forEach((key) => {
        this.$set(this.$data, key, originData[key]);
      });
    },
    close() {
      this.$emit('close');
    },
    handleBankLast(e) {
      if (
        !/Backspace/.test(e.key) &&
        (!/[0-9]/.test(e.key) || !(this.form.bank_last_digits.length < 4))
      ) {
        e.preventDefault();
      }
    },
    handleValiditySeconds(v) {
      if (v < 0) {
        this.$nextTick(() => {
          this.form.validity_seconds = 0;
          return;
        });
      }
      this.form.validity_seconds = Math.round(Number(v));
    },
    async submit() {
      if (!this.$refs.form.validate()) return;

      this.loading = true;
      const isNew = this.localData.mode === 'new';
      const firedApi = isNew ? addCard : editCard;
      const passdata = { ...this.form };
      passdata.name_1 = this.name1;
      passdata.name_2 = this.name2;
      passdata.bank_last_digits = this.bankLastDigits;
      passdata.balance = Number(passdata.balance);

      if (!isNew) {
        passdata.id = this.passData.id;
      }

      const Channel = await firedApi(passdata);
      if (!Channel.error) {
        this.$emit('save');
        this.$toast('保存成功');
        this.close();
      } else {
        this.$toast.error('保存失敗');
      }

      this.loading = false;
    }
  }
};
</script>

<style lang="scss" scoped>
//
</style>
