<template>
  <!-- eslint-disable  -->
  <div>
    <div class="d-sm-flex mb-3 text-Heading6 Medium d-none">
      {{ $store.getters['app/getRouteTitle'] }}
      <v-spacer></v-spacer>
    </div>

    <FilterBox
      :open.sync="isFilterOpen"
      v-model="filter"
      :loading="isLoading"
      :options="filterOptions"
      @search="fetchData"
    />

    <SizeBox height="24" class="d-none d-sm-flex" />

    <v-card
      outlined
      :color="$vuetify.breakpoint.smAndDown ? 'transparent' : null"
    >
      <v-card-text class="d-none d-sm-flex align-center">
        <v-dialog v-model="isInfoOpen" v-if="$vuetify.breakpoint.smAndDown">
          <v-card>
            <v-card-text class="py-3">
              商戶： 共有 {{ result.list.length }} 個
              <br />
              代理商： 共 {{ result.header_info.total_agent || 0 }} 個
              <br />
              可提現金額： {{ result.header_info.total_amt || 0 }} 元
              <br />
              凍結金額： {{ result.header_info.total_frozen_amt || 0 }} 元
            </v-card-text>
          </v-card>
        </v-dialog>
        <div v-else class="d-flex align-center flex-wrap mb-4 mb-sm-0">
          商戶： 共有 {{ result.list.length }} 個
          <v-divider vertical class="mx-3"></v-divider>
          代理商： 共 {{ result.header_info.total_agent || 0 }} 個
          <v-divider vertical class="mx-3"></v-divider>
          可提現金額： {{ result.header_info.total_amt || 0 }} 元
          <v-divider vertical class="mx-3"></v-divider>
          凍結金額： {{ result.header_info.total_frozen_amt || 0 }} 元
        </div>
        <v-spacer></v-spacer>

        <v-btn
          rounded
          color="primary"
          dark
          depressed
          @click="openForm('Info', null, 'new')"
          :disabled="!$getGrantValue('cards_list.add')"
        >
          新增子帳戶
        </v-btn>
        <SizeBox width="12" />
        <ExportBtn
          :items="result.list"
          :headers="headers"
          :file-name="`${
            $store.getters['app/getRouteTitle']
          } ${$moment().format('YYYY-MM-DD_H:mm:ss')}`"
          sheet-name="sheetName"
          :labels="labels"
          :exclude="['actions']"
        />
      </v-card-text>
      <DataTable
        :loading="isLoading"
        :list="result.list"
        :total="result.total"
        :fields="headers"
        :labels="labels"
        :replace="['status', 'enabled_auto_collect', 'actions']"
        @get-list="fetchData"
      >
        <template #item.status="{ item }">
          <div class="flex-center">
            <v-switch
              color="Primary"
              :input-value="item.status"
              @change="(v) => (item.status = changeStatus(item) ? !v : v)"
              :disabled="
                (!!item.abnormal_status && !item.status) ||
                !$getGrantValue('cards_list.status') ||
                item.isStatusChanging
              "
              inset
            ></v-switch>
          </div>
        </template>

        <template #item.enabled_auto_collect="{ item }">
          <div class="flex-center">
            <v-switch
              color="Primary"
              :input-value="item.enabled_auto_collect"
              @change="
                (v) =>
                  (item.enabled_auto_collect = changeEnabledAutoCollect(item)
                    ? !v
                    : v)
              "
              :disabled="
                (!!item.abnormal_status && !item.enabled_auto_collect) ||
                !$getGrantValue('cards_list.enabled_auto_collect') ||
                item.isEnabledAutoCollectChanging
              "
              inset
            ></v-switch>
          </div>
        </template>

        <template #item.actions="{ item }">
          <div class="flex-center">
            <v-menu>
              <template #activator="{ on }">
                <v-btn
                  v-on="on"
                  color="Secondary100"
                  rounded
                  :small="$vuetify.breakpoint.smAndDown"
                >
                  操作
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list rounded>
                <v-list-item
                  v-for="(key, i) in item.actions"
                  :key="`key:${item.id} ${i}`"
                  @click="
                    key.action && handleAction((vm) => key.action(vm, item))
                  "
                  :disabled="key.grant ? !$getGrantValue(key.grant) : false"
                >
                  <v-icon v-if="!!key.icon" size="18" class="mr-1">
                    {{ key.icon }}
                  </v-icon>
                  {{ key.label }}
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </template>
      </DataTable>
    </v-card>

    <InfoForm
      :value="showingForm === 'Info'"
      @close="showingForm = null"
      @save="fetchData"
      :passData="passData"
    />

    <CollectRuleForm
      :value="showingForm === 'CollectRule'"
      @close="showingForm = null"
      @save="fetchData"
      :passData="passData && passData.collect_rule"
    />
    <FunctionFab
      @info="isInfoOpen = true"
      @refresh="fetchData()"
      @open-filter="isFilterOpen = true"
      :no-filter="!filterOptions.length"
      :no-info="!Object.entries(result.header_info).length"
    >
      <v-list-item
        v-if="$getGrantValue('cards_list.add')"
        @click="openForm('Info', null, 'new')"
      >
        <v-icon>mdi-plus</v-icon>
        <span class="text-no-wrap">新增子帳戶</span>
      </v-list-item>
    </FunctionFab>
  </div>
</template>

<script>
import { editCard, editCardStatus, getCards, removeCard } from '@/api/cards';
import { getCollectChannels } from '@/api/collectChannels';
import InfoForm from '@/components/channel/accounts/InfoForm.vue';
import CollectRuleForm from '@/components/channel/accounts/CollectRuleForm.vue';
import { getMerchants } from '@/api/merchants';

const actions = [
  {
    label: '編輯',
    icon: 'mdi-pencil-outline',
    grant: 'cards_list.edit',
    action: (vm, v) => {
      vm.openForm('Info', v, 'edit');
    }
  },
  {
    label: '風控',
    icon: 'mdi-transit-detour',
    grant: 'cards_list.rule_setting',
    action: (vm, v) => {
      vm.openForm('CollectRule', v);
    }
  },
  {
    label: '刪除',
    icon: '',
    grant: 'cards_list.remove',
    action: (vm, v) => {
      vm.$confirm({
        title: '刪除',
        content: '刪除之後，資料將無法復原，您確定要刪除？',
        btn: {
          color: 'error',
          text: '刪除'
        },
        callback: async () => {
          await removeCard({ id: v.id });
          vm.$toast('刪除成功');
          vm.fetchData();
        }
      });
    }
  }
];

export default {
  components: { InfoForm, CollectRuleForm },
  data() {
    return {
      ...this.$clone(this.$commonSchema),
      getCollectChannels,
      headers: [
        {
          text: '使用者帳號',
          value: 'user.account',
          align: 'center',
          width: '90px'
        },
        {
          text: '初始餘額',
          value: 'balance',
          align: 'center',
          width: '90px'
        },
        {
          text: '最低餘額限制',
          value: 'min_balance',
          align: 'center',
          width: '90px'
        },
        {
          text: '最大餘額限制',
          value: 'max_balance',
          align: 'center',
          width: '90px'
        },
        {
          text: '所屬通道',
          value: 'collect_channel.name',
          align: 'center',
          width: '120px'
        },
        {
          text: '異常狀態',
          value: 'abnormal_status',
          align: 'center',
          width: '120px'
        },

        { text: '名稱', value: 'name1', align: 'center', width: '180px' },
        { text: '帳戶狀態', value: 'status', align: 'center', width: '108px' },
        {
          text: '自動上分',
          value: 'enabled_auto_collect',
          align: 'center',
          width: '108px'
        },
        {
          text: '今日成功訂單筆數',
          value: 'today_success_order',
          align: 'center',
          width: '64px'
        },
        {
          text: '今日提交訂單筆數',
          value: 'today_total_order',
          align: 'center',
          width: '64px'
        },
        {
          text: '今日成功金額',
          value: 'today_success_amount',
          align: 'center',
          width: '64px'
        },
        {
          text: '今日提交金額',
          value: 'today_total_amount',
          align: 'center',
          width: '64px'
        },
        {
          text: '成功訂單筆數',
          value: 'success_order',
          align: 'center',
          width: '90px'
        },
        {
          text: '提交訂單筆數',
          value: 'total_order',
          align: 'center',
          width: '90px'
        },
        {
          text: '成功金額',
          value: 'success_amount',
          align: 'center',
          width: '64px'
        },
        {
          text: '提交金額',
          value: 'total_amount',
          align: 'center',
          width: '64px'
        },
        {
          text: '成功率(%)',
          value: 'success_rate',
          align: 'center',
          width: '78px'
        },

        { text: '輪詢權重', value: 'priority', align: 'center', width: '64px' },
        {
          text: '風控模式',
          value: 'collect_rule.mode',
          align: 'center',
          width: '110px'
        },
        { text: '操作', value: 'actions', align: 'center' }
      ],
      labels: {
        abnormal_status: [
          { value: '', text: '正常', color: 'Success300' },
          { value: 'zero', text: '餘額為 0', color: '' },
          { value: 'frozen', text: '銀行停用', color: 'Error300' },
          { value: 'reach_max', text: '滿額', color: 'Error400' },
          { value: 'risk_control', text: '銀行風控', color: 'Alert500' },
          { value: 'judiciary', text: '銀行司法', color: 'Alert400' },
          { value: 'cancelled', text: '銀行註銷', color: 'Alert300' },
          { value: 'reach_min', text: '已達最低餘額限制', color: 'Error300' }
        ],
        'collect_rule.mode': [
          { value: 'inherit_channel', text: '繼承通道', color: 'Primary050' },
          { value: 'self_define', text: '自定義', color: 'Alert400' }
        ]
      },
      filterOptions: [
        {
          label: '建立時間',
          type: 'timeSet',
          name: 'created_at',
          range: true,
          withTime: true
        },
        {
          label: '所屬通道',
          type: 'autocomplete',
          name: 'collect_channel_id',
          query: 'channelId',
          api: () => getCollectChannels({ page: 1, limit: 200 })
        },
        {
          label: '狀態',
          type: 'select',
          name: 'status',
          items: [
            { text: '開啟', value: true },
            { text: '關閉', value: false }
          ]
        },
        {
          label: '異常狀態',
          type: 'select',
          name: 'abnormal_status',
          items: [
            { value: '', text: '正常' },
            { value: 'zero', text: '餘額為 0' },
            { value: 'frozen', text: '銀行停用' },
            { value: 'reach_max', text: '滿額' },
            { value: 'risk_control', text: '銀行風控' },
            { value: 'judiciary', text: '銀行司法' },
            { value: 'cancelled', text: '銀行註銷' },
            { value: 'reach_min', text: '已達最低餘額限制' }
          ]
        },
        {
          label: '最小餘額',
          type: 'text',
          name: 'min_balance'
        },
        {
          label: '最大餘額',
          type: 'text',
          name: 'max_balance'
        },
        {
          label: '名稱',
          type: 'text',
          name: 'query'
        }
      ]
    };
  },

  created() {
    this.fetchData();
  },

  methods: {
    openForm(name, data = {}, mode = '') {
      this.showingForm = name;

      this.$set(this, 'passData', {
        mode,
        ...data
      });
    },
    handleAction(fn) {
      fn(this);
    },
    async fetchData(condition = this.condition) {
      this.isLoading = true;
      this.condition = condition;

      const Cards = await getCards({
        ...this.filter,
        ...condition
      });
      const Merchants = await getMerchants({
        page: 1,
        limit: 1
      });
      if (!Cards.error) {
        this.result.total = Cards.total;
        this.result.page = Cards.page;
        if (!Merchants.error) this.result.header_info = Merchants.header_info;

        this.$set(
          this.result,
          'list',
          Cards.items.map((item) => ({
            ...item,
            success_rate:
              Number(this.$num.multiply(item.success_rate, 100)).toFixed(2) +
              '%',
            name1: `${item.bank_holder}-${item.bank_name.replace(
              /銀行|银行/g,
              ''
            )}-${item.bank_last_digits}`,
            actions
          }))
        );
      }

      this.isLoading = false;
    },
    async changeStatus(item) {
      item.isStatusChanging = true;
      const { error } = await editCardStatus({
        ...item,
        status: !item.status
      });

      if (error) {
        return false;
      } else {
        item.status = !item.status;
        item.isStatusChanging = false;
        return true;
      }
    },
    async changeEnabledAutoCollect(item) {
      item.isEnabledAutoCollectChanging = true;
      const { error } = await editCard({
        ...item,
        enabled_auto_collect: !item.enabled_auto_collect
      });

      if (error) {
        return false;
      } else {
        item.enabled_auto_collect = !item.enabled_auto_collect;
        item.isEnabledAutoCollectChanging = false;
        return true;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
//
</style>
