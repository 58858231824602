<template>
  <v-dialog
    :value="value"
    scrollable
    persistent
    :overlay="false"
    max-width="800"
    transition="slide-y-transition"
  >
    <v-card min-height="500">
      <v-card-title class="pos-r">
        子帳戶風控
        <v-spacer></v-spacer>
        <v-btn
          rounded
          color="Secondary100"
          fab
          x-small
          class="b-rad-12"
          @click="close"
        >
          <v-icon>mdi-window-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text>
        <SizeBox height="48" />
        <v-form ref="form">
          <v-select
            rounded
            label="風控模式"
            :items="modes"
            v-model="form.mode"
            mandatory
            :rules="[$rules.required]"
            outlined
          ></v-select>

          <v-expand-transition>
            <v-card v-if="form.mode === 'self_define'" outlined>
              <v-card-text>
                <v-text-field
                  rounded
                  label="每日最大成功訂單筆數"
                  :rules="[$rules.required]"
                  :value="form.daily_max_success_order"
                  @input="(v) => (form.daily_max_success_order = Number(v))"
                  type="number"
                  outlined
                ></v-text-field>
                <v-text-field
                  rounded
                  label="當天總金額"
                  :rules="[$rules.required]"
                  :value="form.daily_max_amt"
                  @input="(v) => (form.daily_max_amt = Number(v))"
                  type="number"
                  outlined
                ></v-text-field>
                <v-text-field
                  rounded
                  label="單筆最小金額"
                  :rules="[$rules.required]"
                  :value="form.single_min_amt"
                  @input="(v) => (form.single_min_amt = Number(v))"
                  type="number"
                  outlined
                ></v-text-field>
                <v-text-field
                  rounded
                  label="單筆最大金額"
                  :rules="[$rules.required]"
                  :value="form.single_max_amt"
                  @input="(v) => (form.single_max_amt = Number(v))"
                  type="number"
                  outlined
                ></v-text-field>
              </v-card-text>
            </v-card>
          </v-expand-transition>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-btn
          rounded
          color="Secondary100"
          depressed
          large
          @click="close"
          :disabled="loading"
        >
          取消
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          rounded
          color="primary"
          dark
          depressed
          large
          :loading="loading"
          @click="submit"
        >
          保存
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { editCollectRule } from '@/api/cards';

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    passData: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      localData: {},
      loading: false,
      modes: [
        {
          text: '繼承通道',
          value: 'inherit_channel'
        },
        {
          text: '自定義',
          value: 'self_define'
        }
      ],
      form: {
        id: '',
        daily_max_success_order: 0,
        single_min_amt: 0,
        single_max_amt: 0,
        daily_max_amt: 0,
        mode: '' // 'inherit_system' or 'self_define'
      }
    };
  },

  watch: {
    value(v) {
      v ? this.init() : this.reset();
    }
  },

  methods: {
    init() {
      Object.keys(this.form).forEach((key) => {
        typeof this.passData[key] !== 'undefined' &&
          (this.form[key] = this.passData[key]);
      });
      this.$refs.form && this.$refs.form.resetValidation();
    },
    reset() {
      this.$set(this, 'localData', {});
      this.$refs.form && this.$refs.form.reset();
    },
    close() {
      this.$emit('close');
    },
    async submit() {
      if (!this.$refs.form.validate()) return;

      this.loading = true;
      const passdata = { ...this.form };
      passdata.daily_max_success_order = Number(
        passdata.daily_max_success_order
      );
      passdata.single_min_amt = Number(passdata.single_min_amt);
      passdata.single_max_amt = Number(passdata.single_max_amt);
      passdata.daily_max_amt = Number(passdata.daily_max_amt);

      const { error } = await editCollectRule(passdata);
      if (!error) {
        this.$emit('save');
        this.$toast('保存成功');
        this.close();
      } else {
        this.$toast.error('保存失敗');
      }

      this.loading = false;
    }
  }
};
</script>

<style lang="scss" scoped>
//
</style>
